import React, {useState, useEffect} from 'react'

function DataVizCard(
  {
    imgPath
  }
) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (windowWidth <= 500) {
    return (
      <div className="bg-white rounded-md w-[22vw] h-[17vw] m-4 text-center flex justify-center items-center">
        <img className="object-contain py-5" src={imgPath} alt="" />
      </div>
    )
  } else if (windowWidth <= 1024) {
    return (
      <div className="bg-white rounded-md w-[20vw] h-[15vw] m-4 text-center flex justify-center items-center">
        <img className="object-contain py-5" src={imgPath} alt="" />
      </div>
    )
  } else if (windowWidth <= 1350) {
    return (
      <div className="bg-white rounded-md w-[15vw] h-[10vw] m-4 text-center flex justify-center items-center">
        <img className="object-contain py-5" src={imgPath} alt="" />
      </div>
    )
  }
  return (
    <div className="bg-white rounded-md w-[15vw] h-[8vw] m-4 text-center flex justify-center items-center">
      <img className="object-contain py-5" src={imgPath} alt="" />
    </div>
  )
}

export default DataVizCard