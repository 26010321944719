import React, {useState, useEffect} from 'react'

function IntroHeader(
  {
    headingText
  }
) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 850);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 850);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="font-poppins-extraBold text-[2.75vw] max-[850px]:text-[8vw] text-left max-[850px]:text-center relative w-fit mx-auto min-[850px]:mt-5 inline-block">
        {headingText}
        {
          isDesktop
          ?
          <div className="font-poppins-regular font-semibold text-[#8398AD] text-[1vw] max-[850px]:text-[2vw] text-left self-end absolute top-[-20px]">
            NeoGPT<sup>TM</sup>
          </div>
          :
          ''
        }
      </div>
    </div>
  )
}

export default IntroHeader