function IntroTestimonial(
  {
    testimonialMessage,
    avatarIconPath,
    avatarName,
    avatarCompany
  }
) {
  return (
    <div className="min-[850px]:relative grid grid-rows-2 text-[1.5vw] max-[850px]:text-[2.5vw] max-[850px]:left-[20%]">
      <div className="font-poppins-regular font-extrabold text-left py-2 max-[850px]:text-center text-[1.25vw] max-[850px]:text-[5vw]">
        {testimonialMessage}
      </div>
      <div className="my-auto max-[850px]:flex max-[850px]:justify-center max-[850px]:items-center">
        <div className="flex flex-row font-poppins-regular font-bold scale-90 lg:scale-100">
          <img className="h-12 max-[850px]:h-20" src={avatarIconPath} alt="Profile" />
          <div className="flex flex-col my-auto px-5">
            <div className="text-[1.25vw] max-[850px]:text-[5vw]">
              {avatarName}
            </div>
            <div className="text-[1vw] max-[850px]:text-[4vw]">
              {avatarCompany}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntroTestimonial