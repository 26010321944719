import React, { useState, useEffect } from 'react';
import BlogCard from './BlogCard';
import IMAGES from '../static/images';

function formatDate(dateString) {
  const options = { day: 'numeric', month: 'short' };
  const date = new Date(dateString);
  const day = new Intl.DateTimeFormat('en', options).format(date);
  
  const dayNumber = date.getDate();
  let suffix = 'th';
  if (dayNumber === 1 || dayNumber === 21 || dayNumber === 31) {
    suffix = 'st';
  } else if (dayNumber === 2 || dayNumber === 22) {
    suffix = 'nd';
  } else if (dayNumber === 3 || dayNumber === 23) {
    suffix = 'rd';
  }
  
  return `${dayNumber}${suffix} ${day.split(' ')[0]}`;
}

function  BlogCards({ tag, team }) {
  console.info(tag)
  console.info(team)
  const apiUrl = 'https://api.onfinance.ai/blogs/blogposts'
  const queryParams = new URLSearchParams();
  queryParams.append('tags', tag);
  queryParams.append('tags', team);
  const urlWithParams = `${apiUrl}?${queryParams.toString()}`;
  const profiles = [
    IMAGES.profile1,
    IMAGES.profile2,
    IMAGES.profile3
  ]

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch(urlWithParams, {
      method: "GET",
      headers: { 
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InByaXllc2hAb25maW5hbmNlLmluIiwiZXhwIjoxNjk2MTQ5NDUzfQ.faqq8zDgcftY1dZIIFrNBh3ABf65vb0FtFe4t0CkS7U'
      }
    })
    .then(response => response.json())
    .then(data => {
      data = data.blog_posts
      setBlogs(data.slice(0, 3)); // get only the first 3 blogs
    })
    .catch(error => {
      console.error('Error fetching the API', error);
    });
  }, [urlWithParams]);

  return (
    <div className="min-[850px]:grid min-[850px]:grid-cols-3 min-[850px]:gap-x-8">
      {blogs.map((blog, index) => (
        <div key={index}>
          <BlogCard
            heading={blog.Name}
            date={formatDate(blog.Updated)}
            imgPath={blog.FeaturedImage}
            profile={profiles[index]}
            url={blog.Url}
          ></BlogCard>
        </div>
      ))}
    </div>
  );
}

export default BlogCards;