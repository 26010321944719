import FeatureItem from "./FeatureItem"

function FeatureItems(
  {
    windowWidth,
    features
  }
) {
  if (windowWidth > 850) {
    return (
      <><div className="min-[850px]:grid min-[850px]:grid-rows-2 min-[850px]:place-content-end max-[850px]:inline-block">
        <FeatureItem
          align="items-end"
          featureIconPath={features.iconItem1}
          featureText={features.item1}
        ></FeatureItem>
        <FeatureItem
          align="items-start"
          featureIconPath={features.iconItem2}
          featureText={features.item2}
        ></FeatureItem>
      </div><div className="min-[850px]:grid min-[850px]:grid-rows-2 ml-3 max-[850px]:inline-block">
          <FeatureItem
            align="items-end"
            featureIconPath={features.iconItem3}
            featureText={features.item3}
          ></FeatureItem>
          <FeatureItem
            align="items-start"
            featureIconPath={features.iconItem4}
            featureText={features.item4}
          ></FeatureItem>
        </div></>
    )
  } else {
    return (
      <div>
        <div className="grid grid-rows-4">
          <FeatureItem
            align="items-end"
            featureIconPath={features.iconItem1}
            featureText={features.item1}
          ></FeatureItem>
          <FeatureItem
            align="items-start"
            featureIconPath={features.iconItem2}
            featureText={features.item2}
          ></FeatureItem>
          <FeatureItem
            align="items-end"
            featureIconPath={features.iconItem3}
            featureText={features.item3}
          ></FeatureItem>
          <FeatureItem
            align="items-start"
            featureIconPath={features.iconItem4}
            featureText={features.item4}
          ></FeatureItem>
        </div>
      </div>
    )
  }
}

export default FeatureItems