import React, {useState, useEffect} from 'react'
import clsx from 'clsx'

function FeatureItem(
  {
    align,
    featureIconPath,
    featureText
  }
) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 850);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 850);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={clsx("flex py-3", isDesktop ? align : "items-center")}>
      <img className="h-[2vw] max-[850px]:h-[10vw] scale-90 lg:scale-100 my-1" src={featureIconPath} alt={featureText} />
      <div className="font-poppins-boldItalic italic text-[#5A5A5A] px-3 text-[1.5vw] max-[850px]:text-[5vw]">
        {featureText}
      </div>
    </div>
  )
}

export default FeatureItem