import React, { useEffect, useState } from "react";

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      setIsOpen(false);
    });
  });

  const dropdownItems = [
    {
      menuOption: "Features",
      url: "https://www.onfinance.ai/#feature",
    },
    {
      menuOption: "NeoGPT",
      url: "https://www.onfinance.ai/",
    },
    {
      menuOption: "Pricing",
      url: "https://www.onfinance.ai/#pricing",
    },
    {
      menuOption: "Research",
      url: "https://research.onfinance.ai/",
    },
    {
      menuOption: "Sales",
      url: "https://sales.onfinance.ai",
    },
    {
      menuOption: "Support",
      url: "https://cs.onfinance.ai/",
    },
    {
      menuOption: "Contact Us",
      url: "https://www.onfinance.ai/#contact",
    },
  ];

  return (
    <div className="dropdown text-right justify-end rounded-lg relative">
      <button
        className="dropbtn"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="menu-button">
          <div className="dash"></div>
          <div className="dash"></div>
          <div className="dash"></div>
        </div>
      </button>
      <div className={`dropdown-content ${isOpen ? "active" : "inactive"}`}>
        {dropdownItems.map((item, index) => (
          <a
            className="block py-1 px-1"
            key={index}
            href={item.url}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            target="blank"
          >
            {item.menuOption}
          </a>
        ))}
      </div>
    </div>
  );
}

export default DropdownMenu;
